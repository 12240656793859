import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { FirebaseService } from '../services/firebase.service';
import { take } from 'rxjs/operators';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PrintService } from '../services/print.service';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';

@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.page.html',
  styleUrls: ['./wallet.page.scss']
})
export class WalletPage implements OnInit {
  
  bluetoothList:any=[];
  selectedPrinter:any;

  products = [];
  cartTotal: any  = 0;
  descForm: FormGroup;
  qty: any;
  kues: string;
  title: string;
  myText: any;
  subTotal: any = 0;
  count: any = 0;

  constructor(private firebaseService: FirebaseService, private modalCtrl: ModalController, private alertCtrl: AlertController, public fb: FormBuilder, private print:PrintService, private networkInterface: NetworkInterface) { 
  }
  
  ngOnInit() {
    
    this.descForm = this.fb.group({
      catatan: ['']
    })

    let tempTotal = 0;
    let count: any = 0;
    let counts: any = 0;
    let title: string;
    let subTotal = 0;
    let kues: string;

    const cartItems = this.firebaseService.cart.value;

    this.firebaseService.getProducts().pipe(take(1)).subscribe(allProducts => {
      
      this.products = allProducts.filter(p => cartItems[p.id]).map(product => {
        return {
          ...product,
          qty: count = cartItems[product.id],
          totalqty: counts += cartItems[product.id],
          kues: title = product.title,
          total: cartItems[product.id] * product.price,
          subTotal: tempTotal += product.price * cartItems[product.id]
        };
      });
      this.qty = counts;
      this.kues = title;
      this.cartTotal = tempTotal;
      this.myText = "\nTakeAway \nJl. Kalisari No.9, Jakarta Timur\nTerimakasih sudah berkunjung\nSelamat menikmati :)\n--------------------------------\nItem : "+this.kues+"\n\nTotal Item : "+this.qty+" Box\n--------------------------------\nTotal Harga : Rp " + this.cartTotal + "\n\nCatatan : "+this.descForm.value;
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  //This will list all of your bluetooth devices
  async listPrinter() {
    this.firebaseService.checkoutCart(this.cartTotal, this.descForm.value, this.kues, this.qty);

    //The text that you want to print
    const alert = await this.alertCtrl.create({
      header: 'Success',
      message: 'Thanks for your order',
      buttons: ['Continue shopping']
    });
 
    await alert.present();
    this.modalCtrl.dismiss();
    console.log(this.myText);
    console.log(this.descForm.value);

    this.print.searchBluetoothPrinter()
     .then(resp=>{
 
      //List of bluetooth device list
      this.bluetoothList=resp;
    });
  }

  //This will store selected bluetooth device mac address
  selectPrinter(macAddress) {
    //Selected printer macAddress stored here
    this.selectedPrinter=macAddress;
  }

  //This will print
  printStuff() {
    this.print.sendToBluetoothPrinter(this.selectedPrinter, this.myText);

    console.log(this.myText);
    console.log(this.descForm.value);
  }
}