import { Component, OnInit } from '@angular/core';
import { Animation, AnimationController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.page.html',
  styleUrls: ['./product-modal.page.scss'],
})
export class ProductModalPage implements OnInit {
  title : any;
  constructor(
    private animationCtrl: AnimationController, 
    private modalCtrl: ModalController
  ) { }

  close() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    this.title = localStorage.getItem('title');
  }

}
