import { Component, OnInit } from '@angular/core';
import { AlertController, Animation, AnimationController, ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FirebaseService } from '../services/firebase.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-finance-modal',
  templateUrl: './finance-modal.page.html',
  styleUrls: ['./finance-modal.page.scss'],
})
export class FinanceModalPage implements OnInit {
  // Deklarasi variabel yang dibutuhkan
  financeForm: FormGroup;
  orderTotal: any  = 0;
  finance = [];
  financeTotal: any = 0;
  finorderTotal: any = 0;
  fileName: any;

  constructor(
    private firebaseService: FirebaseService, 
    private modalCtrl: ModalController, 
    public fb: FormBuilder, 
    private alertCtrl: AlertController
  ) {}

  ngOnInit() {
    this.financeForm = this.fb.group({
      keterangan: [''],
      pengeluaran: ['']
    });
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async submitForm() {
    // Menambahkan data finance
    this.firebaseService.addFinanceForm(this.financeForm.value);

    // Menampilkan alert untuk konfirmasi
    const alert = await this.alertCtrl.create({
      header: 'Success',
      message: 'Berhasil input data finance',
      buttons: ['Continue']
    });
    await alert.present();

    // Memuat ulang data finance setelah data baru ditambahkan
    let finTotal = 0;
    let lastUpdate: any;

    this.firebaseService.getFinance().pipe(take(1)).subscribe((allItems: any[]) => {     
      this.finance = allItems.map(finance => {
        return {
          ...finance,
          fingrandTotal: finTotal += finance.description['pengeluaran'],
          fileName: lastUpdate = finance.lastUpdateFinance
        };
      });
      this.financeTotal = finTotal;
      this.fileName = 'Finance - ' + lastUpdate.toDate();
      this.finorderTotal = this.orderTotal - this.financeTotal;
    });
    
    // Menutup modal setelah semua proses selesai
    this.modalCtrl.dismiss();
  }
}
