import { Component, OnInit } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { Animation, AnimationController, ModalController } from '@ionic/angular';
import { FirebaseService } from '../services/firebase.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tab3',
  templateUrl: 'tab3.page.html',
  styleUrls: ['tab3.page.scss']
})
export class Tab3Page implements OnInit {
  orders: Observable<any[]>;
  constructor(
    private firebaseService: FirebaseService, 
    private modalCtrl: ModalController, 
    private alertCtrl: AlertController,
    public plt: Platform
  ) {}
  
  ngOnInit() {
    this.orders = this.firebaseService.getOrders();
  }

  close() {
    this.modalCtrl.dismiss();
  }
}