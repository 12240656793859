import { Injectable } from '@angular/core';
import { Router } from "@angular/router";
import { AlertController, LoadingController } from '@ionic/angular';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore } from '@angular/fire/firestore';

import { ProviderService } from '../provider.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // login
  data_user : any;
  respAPI : any;
  email : string = "";
  emailreset : string = "";

  // register
  username : string = "";
  wallet_address : string = "";
  password : string = "";
  password2 : string = "";
  uid : any;
  acceptTerms : any;
  marked : any;
  constructor(
    private angularFireAuth: AngularFireAuth,
    private fs: AngularFirestore,
    private router: Router,
    private alertController: AlertController,
    private loadingController: LoadingController,
  ) {}

  async login({ email, password }) {
    try {
      const user = this.angularFireAuth.signInWithEmailAndPassword(email, password);
      console.log(user);
      localStorage.setItem('email', email);
      return user;
    } catch (e) {
      return null;
    }
  }

  logout() {
    localStorage.clear();
  }
}
